import { get, post, put } from '@/utils/http';
export default {
    getOrderInfo: (data) => get('/order/getOrderInfo', data),
    getShopExpList: () => get('/express/getShopExpList'),
    cancelOrder: (data) => post('/order/cancel', data),
    updateOrder: (data) => post('/order/updateOrder', data),
    unionNotifyOrder: (data) => get('/order/unionNotifyOrder', data),
    refuseOrder: (data) => get('/order/refuseOrder', data),
    getTradeOrderList: (data) => get('/tradeorder/getTradeOrderList', data),
    tradeOrderCount: (data) => get('/tradeorder/tradeOrderCount', data),
    addBankInfo: (data) => post('/order/addBankInfo', data),
    getThunderGetBankInfo: (data) => post('/order/thunderGetBankInfo', data),
    thunderpayWithdrawal: (data) => post('/order/thunderWithdrawal', data),
    help2payWithdrawal: (data) => post('/order/help2PayOut', data),
    skrillWithdrawal: (data) => post('/order/withdrawal/skrill', data),
    onetwopayWithdrawal: (data) => post('/order/withdrawal/1-2-pay', data),
    worldpayWithdrawal: (data) => post('/order/worldPayWithdrawal', data),
    gritpayWithdrawal: (data) => post('/order/gritWithdrawal', data),
    getWithdrawalOrderList: (data) => get('/withdrawal/withdrawalOrder', data),
    getWithdrawalOrderInfo: (id, data) => get(`/withdrawal/withdrawalOrder/${id}`, data),
    updateWithdrawalOrderInfo: (data) => put('/withdrawal/withdrawalOrder', data),
    reWithdrawalOrderCallback: (data) => put('/withdrawal/reWithdrawalOrderCallback', data),
    getWithdrawalChannel: () => get('/withdrawal/channel'),
    apiWithdrawal: (data) => post('/withdrawal/apiWithdrawal', data),
    worldpayPaymentToken: (data) => get('/order/getPaymentToken', data),
    getCNYRate: () => get('/order/getCNYRate'),
    resendPostCrm: (data) => get('/order/resendPostCrm', data)
};
