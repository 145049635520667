var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import { BrowserRouter as Router } from 'react-router-dom';
import Launcher from '@/pages/App/Launcher';
import i18n from '@/common/I18n';
import loaders from './loaders';
import { common, user } from '@/api';
import { Error, Loading } from '@/common/components';
import Button from "antd/lib/button";
export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            routes: []
        };
        this.getMenu = () => __awaiter(this, void 0, void 0, function* () {
            const menu = yield common.getMenuList().then((res) => {
                if (res.ret_code === 0) {
                    return res.ret_msg;
                }
                else {
                    return [];
                }
            }).catch(() => []);
            this.setState({
                routes: menu
            }, () => {
                this.setState({ isLoading: false });
            });
        });
        this.logout = () => {
            user.logout().finally(() => {
                sessionStorage.removeItem('sAccountSetupData');
                window.location.replace('/v2/signin');
            });
        };
        this.getMenu();
    }
    render() {
        const lang = i18n.getDefault();
        const { isLoading, routes = [] } = this.state;
        const Error403 = Error({
            config: {
                status: '403', extra: React.createElement(Button, { className: 'height36', type: 'primary', onClick: this.logout }, intl.get('Global.BackToLogin'))
            }
        });
        return !isLoading ? (routes.length ?
            React.createElement(Router, null,
                React.createElement(Launcher, { lang: lang, routesConfig: { routes, loaders } })) : React.createElement(Error403, null)) : React.createElement(Loading, { debounce: 500 });
    }
}
