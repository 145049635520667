var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import * as intl from 'react-intl-universal';
import { merge } from './utils';
import locales from './locales';
export default class Language {
}
_a = Language;
Language.initLang = 'en-US';
Language.supported = [
    {
        key: 'zh-CN', name: intl.get('Locales.zh_CN').d('简体中文'), icon: 'simplified', locale: 'zh-cn'
    },
    {
        key: 'zh-TW', name: intl.get('Locales.zh_TW').d('繁體中文'), icon: 'traditional', locale: 'zh-tw'
    },
    {
        key: 'en-US', name: intl.get('Locales.en_US').d('English'), icon: 'english', locale: 'en-gb'
    }
];
Language.code = {
    zh: 'zh-CN',
    tw: 'zh-TW',
    en: 'en-US',
};
Language.isSupported = (code) => {
    const langArr = Language.supported.map(({ key }) => key);
    if (langArr.includes(code))
        return code;
    return Language.code[code];
};
Language.getDefault = () => {
    if (window.localStorage && window.localStorage.getItem('_uLang') && _a.isSupported(window.localStorage.getItem('_uLang'))) {
        return window.localStorage.getItem('_uLang');
    }
    if (window.navigator && window.navigator.language && _a.isSupported(window.navigator.language)) {
        return _a.isSupported(window.navigator.language);
    }
    return Language.initLang;
};
Language.setLanguage = (language, reload = true) => __awaiter(void 0, void 0, void 0, function* () {
    window.localStorage && window.localStorage.setItem('_uLang', language);
    reload && window.location.reload();
});
Language.getDisplay = Language.supported;
Language.init = (language, userLocales = {}) => __awaiter(void 0, void 0, void 0, function* () {
    window.localStorage && window.localStorage.setItem('_uLang', language);
    yield intl.init({
        currentLocale: language,
        locales: merge(yield locales(language), userLocales)
    });
});
