import { get, getpath, post, upload } from "@/utils/http";
const getDigitalCurrencyChannel = (data) => get("/setting/getDigitalChannel", data);
const createDigitalCurrencyChannel = (data) => post("/setting/updateDigitalChannel", data);
const editDigitalCurrencyChannel = (data) => post("/setting/updateDigitalChannel", data);
const getSingleDigitalCurrencyChannel = (id, authenticationCode) => getpath("/setting/digitalChannel/" + id + (authenticationCode ? `?authenticateCode=${authenticationCode}` : ''));
const getAllDigitalCurrencyChannel = () => get("/setting/getAllDigitalChannels/");
const getDigitalCurrencyVault = (data) => post("/digital/vault/list", data);
const getDigitalVaultExport = (data) => post("/digital/vault/export", data);
const getDigitalVaultTxnExport = (data) => post("/digital/transaction/export", data);
const getSingleTransactionDetails = (transactionId) => get("/digital/transaction/" + transactionId);
const getTransactionSubstatusesDetails = (transactionId) => get("/digital/transactionStatus/" + transactionId);
const getVaultTransactions = (data) => post("/digital/transaction/list", data);
const getAllDigitalCurrencyChannels = () => get("/setting/digital/channel/list");
const getAssetBalance = (channel, method, data) => post("/digital/invoke/" + channel + "/" + method, data);
const transferAssetBalance = (channel, method, data) => post("/digital/invoke/" + channel + "/" + method, data);
const getFilterList = (searchCriteria, assetId, addressType) => getpath(`/digital/vault/getFilterList?searchCriteria=${searchCriteria}&assetId=${assetId}&addressType=${addressType}`);
const getMaxCount = () => get("/digital/getMaxCount");
const getAssetList = () => get('/digital/getAssetList');
const resendWebhook = (channel, method, data) => post("/digital/invoke/" + channel + "/" + method, data);
const batchUploadAddress = (data) => upload("/digital/batchUploadVaultAddress", data);
const getSaveTrxListing = (data) => post("/digital/resourceRenting/list", data);
const getSaveTrxOrderDetails = (orderNo, data) => post(`/digital/resourceRenting/getOrderDetails/${orderNo}`, data);
const getSaveTrxAccount = (data) => post('/digital/saveTrx/getUserAccountDetails', data);
const batchCreateSaveTrxOrder = (data) => post('/digital/saveTrx/batchCreate', data);
const transferPreValidation = (orderNo) => get(`/digital/transfer/preCheckValidation/${orderNo}`);
const retryResourceRental = (orderNo) => post(`/digital/resourceRenting/retry/${orderNo}`);
const getResourceRentingAddressesByOrderNo = (orderNo) => get(`/digital/saveTrx/resourceRenting/retrieveAddressIds/${orderNo}`);
export default {
    getDigitalCurrencyChannel,
    getAllDigitalCurrencyChannel,
    getSingleDigitalCurrencyChannel,
    createDigitalCurrencyChannel,
    editDigitalCurrencyChannel,
    getAllDigitalCurrencyChannels,
    getDigitalCurrencyVault,
    getDigitalVaultExport,
    getVaultTransactions,
    getSingleTransactionDetails,
    getDigitalVaultTxnExport,
    getTransactionSubstatusesDetails,
    getAssetBalance,
    transferAssetBalance,
    getFilterList,
    getMaxCount,
    getAssetList,
    resendWebhook,
    batchUploadAddress,
    getSaveTrxListing,
    getSaveTrxOrderDetails,
    getSaveTrxAccount,
    batchCreateSaveTrxOrder,
    transferPreValidation,
    retryResourceRental,
    getResourceRentingAddressesByOrderNo,
};
